import React, {useEffect, useState}               from 'react'
import {Box, Container, useMediaQuery, useTheme,} from '@mui/material'
import Grid                                       from '@mui/material/Unstable_Grid2' // Grid version 2
import {useNavigate}                              from "react-router-dom"
import {doGet}                                    from "../../util/do-fetch"
import {logger}                                   from "../../util/log-utils"
import {ArrowForward}                             from "@mui/icons-material"
import GradientCard                               from "../GradientCard"
import GlassButton                                from "../GlassButton"
import {Helmet}                                   from "react-helmet-async"

const log = logger("MiniShop", 1)

function MiniShop() {

	const navigate = useNavigate()
	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [categories, setCategories] = useState(null)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await doGet(`/mini/shop`)
				const data = await response.json()
				setCategories(data)
			}
			catch (err) {
				log.error(err)
			}
		}
		log.debug(`calling fetchData()`)
		fetchData()
	}, [])

	const handleClickCategory = (category) => {
		navigate(`${category.token}`)
	}

	return <Container maxWidth="lg" sx={{mt: {xs: 3, sm: 3, md: 10}}}>
		<Helmet>
			<title>{"Birdsy - Categories"}</title>
		</Helmet>
		<Grid container spacing={3} justifyContent={"center"}>
			{
				categories && categories.map(c => (
					<Grid key={`cat-${c.id}`} xs={12} md={4} sx={{order: {xs: 3, md: 1}, textAlign: 'center'}}>
						<GradientCard
							title={c.name}
							caption={c.packages && `${c.packages.length} PrepKits`}
							color={"white"}
							backgroundColor1={c.color}
							backgroundColor2={c.color2}
							onClick={() => handleClickCategory(c)}
						>
							<Box display={"flex"}
							     flex={1}
							     mt={isSmallScreen ? 0 : 10}
							     justifyContent={{xs: "flex-start", sm: "flex-start", md: "center"}}
							     alignItems={"center"}
							>
								<GlassButton onClick={() => {
								}}
								             bullet={false}
								             label={"View PrepKits"}
								             icon={<ArrowForward sx={{ml: 1}}/>}
								/>
							</Box>
						</GradientCard>
					</Grid>
				))
			}
		</Grid>
	</Container>

}

export default MiniShop
