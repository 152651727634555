import React, {useEffect, useMemo, useState} from 'react'
import {
	Alert,
	Box,
	Container,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
}                                            from '@mui/material'
import Grid                                  from '@mui/material/Unstable_Grid2' // Grid version 2
import {
	useLocation,
	useNavigate,
	useParams
} from "react-router-dom"
import {
	doGet
}                                            from "../../util/do-fetch"
import {
	logger
}                                            from "../../util/log-utils"
import {
	ArrowBack,
	Search
}                                            from "@mui/icons-material"
import PackageCard                    from "./PackageCard"
import {trackPageView, trackViewItem} from "../../util/analytics"
import {Helmet}                              from "react-helmet-async"

const log = logger("MiniCategory", 1)

function MiniCategory() {

	const theme = useTheme()
	const location = useLocation()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate()
	const {categoryToken} = useParams()
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [packages, setPackages] = useState(null)
	const [filter, setFilter] = useState("")
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const filteredPackages = useMemo(() => {
		return packages &&
			packages.filter(p => p.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1)
	}, [packages, filter])

	useEffect(() => {
		if (selectedCategory) {
			trackPageView(location.pathname)
		}
	}, [selectedCategory])

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				const response = await doGet(`/mini/shop/${categoryToken}`)
				const data = await response.json()
				setSelectedCategory(data.category)
				setPackages(data.packages)
			}
			catch (err) {
				log.error(err)
			}
			finally {
				setLoading(false)
			}
		}
		categoryToken && fetchData()
	}, [categoryToken])

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				const response = await doGet(`/mini/shop/${selectedCategory.token}`)
				const data = await response.json()
				setPackages(data.packages)
			}
			catch (err) {
				log.error(err)
			}
			finally {
				setLoading(false)
			}
		}
		selectedCategory && fetchData()
	}, [selectedCategory])


	const handleClick = (pkg) => {
		navigate(pkg.token)
	}

	return (
		selectedCategory &&
		<Container maxWidth="lg">
			<Helmet>
				<title>{`Birdsy - ${selectedCategory.name}`}</title>
			</Helmet>
			<Grid xs={12} spacing={3} justifyContent="center" py={3}>
				<Box display={"flex"}
				     justifyContent={"center"}
				     alignItems={"center"}
				     ml={-7}
				>
					<IconButton onClick={()=>navigate('..')} sx={{mr: 1}}>
						<ArrowBack fontSize={"large"}/>
					</IconButton>
					<Typography variant={isSmallScreen ? "h5" : "h2"} style={{textAlign: 'center'}}>
						{selectedCategory.name} PrepKits
					</Typography>
				</Box>
			</Grid>
			<Grid xs={12} spacing={3}>
				<Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} pb={3}>
					<TextField
						autoComplete="off"
						fullWidth={true}
						name="package-filter"
						variant="standard"
						style={{
							display: "block",
							borderRadius: "30px",
							width: "100%",
							maxWidth: "800px",
							padding: isSmallScreen ? "5px 15px" : "15px",
							border: "1px solid " + theme.palette.divider
						}}
						placeholder="Find your PrepKit..."
						value={filter}
						onChange={(event) => {
							setFilter(event.target.value)
						}}
						InputProps={{
							disableUnderline: true,
							endAdornment: <InputAdornment position="end">
								<Search/>
							</InputAdornment>,
						}}
					/>
				</Box>
			</Grid>
			{
				error &&
				<Alert severity={"error"}>
					{error}
				</Alert>
			}
			<Grid xs={12} spacing={3}>
				<Box maxWidth={"800px"} margin={"0 auto"}>
					{filteredPackages && filteredPackages.map((pkg) => (
						<Grid item="true"
						      key={`package-${pkg.id}`}
						      border={"0px solid red"}
						      paddingBottom={2}
						>
							<PackageCard packageData={pkg}
							             actionLabel={"Select"}
							             onClick={handleClick}
							             variant={isSmallScreen ? "small" : ""}
							/>
						</Grid>
					))}
				</Box>
			</Grid>

		</Container>
	)


}

export default MiniCategory
