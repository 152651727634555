import React, {createContext, useState, useEffect, useContext, useMemo} from 'react'
import {useParams, Outlet, useNavigate, useLocation}                    from 'react-router-dom'
import {doGet}                                                          from "../../util/do-fetch"
import moment                                                                     from "moment"
import {getSupabase}                                                              from "../../util/supabase-utils"
import {useUserStore}                                                             from "../../state"
import {logger}                                                                                     from "../../util/log-utils"
import {
	Box,
	Button, Card, CardActions, CardContent,
	Chip,
	CircularProgress,
	Container,
	Divider,
	Drawer,
	Fab,
	Link,
	Typography, useMediaQuery,
	useTheme
} from "@mui/material"
import CircularProgressWithLabel from "../CircularProgressWithLabel"
import TopicToc                          from "./TopicToc"
import {ArrowForward, Close, ExpandMore} from "@mui/icons-material"
import ExpiringPrepKit                   from "./ExpiringPrepKit"
import Grid                      from "@mui/material/Unstable_Grid2"
import {isExpired}               from "../../util/prepkit-utils"
import LeftRight                 from "../LeftRight"
import LoadingWithMessages from "../LoadingWithMessages"
import GradientCard from "../GradientCard"
import {Helmet} from "react-helmet-async"

const log = logger("MiniPrepKits", 1)

const MiniPrepKits = () => {
	const theme = useTheme()
	const navigate = useNavigate()
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const location = useLocation()
	const {user} = useUserStore()
	const hash = location.hash
	const [prepKits, setPrepKits] = useState(null)

	const isBack = useMemo(()=>{
		return hash === '#back'
	}, [hash])

	useEffect(() => {
		const fn = async () => {
			await fetchPrepKits()
		}
		fn()
	}, [])

	const fetchPrepKits = async () => {
		const response = await doGet(`/mini/prepkit`)
		const data = await response.json()

		if(data && data.length === 1) {
			// if there's only one, then navigate to it
			const pk = data[0]

			//... unless its expired
			if(!isExpired(pk) && !isBack) {
				navigateToPrepKit(pk)
			}
			else {
				setPrepKits(data)
			}
		}
		else {
			setPrepKits(data)
		}
	}

	const navigateToPrepKit = (prepKit) => {
		navigate(prepKit.id)
	}

	return (
		<Container maxWidth="md">
			<Helmet>
				<title>{'PrepKits - Birdsy'}</title>
			</Helmet>
			<Grid xs={12} spacing={3} justifyContent="center" py={3}>
				<Typography variant={isSmallScreen ? "h4" : "h2"} style={{textAlign: 'center'}}>
					Choose Your PrepKit
				</Typography>
			</Grid>
			<Grid xs={12} xsOffset={0}
			      md={8} mdOffset={2}
			      container
			      spacing={3}
			      justifyContent="center"
			      py={3}
			>
				<Box display={"flex"} flexDirection={"column"}>
					{
						prepKits && prepKits.map(pk => {
							return <Box key={pk.id}
							            sx={{cursor: "pointer", mb: 7}}
							>
								<GradientCard
									title={pk.name}
									caption={
										moment(pk.expires_at)
											.isBefore(moment())
											? `Expired ${moment(pk.expires_at)
												.calendar()}`
											: `Expires ${moment(pk.expires_at)
												.calendar()}`
									}
									color={"white"}
									square={false}
									backgroundColor1={pk.category ? pk.category.color : theme.palette.divider}
									backgroundColor2={pk.category ? pk.category.color2 : theme.palette.divider}
									onClick={() => navigateToPrepKit(pk)}
								/>
							</Box>
						})
					}
					<Box mt={4}>
						<Button sx={{cursor: "pointer"}}
						        onClick={() => navigate('/prep')}
						        fullWidth={true}
						>
							Browse more PrepKits &rarr;
						</Button>
					</Box>

				</Box>


			</Grid>
		</Container>
	)
}

export default MiniPrepKits
