import React, {useEffect}                                from 'react'
import {Box, Container, DialogContent, Link, Typography} from "@mui/material"
import BirdsyMiniLogo                                    from "../BirdsyMiniLogo"
import DialogWithBlurredBackdrop                         from "../DialogBlurred"
import SelectPackageList                                 from "./SelectPackageList"
import useMediaQuery                                     from "@mui/material/useMediaQuery"

const SelectPackage = ({title,
	                    packages,
						onSelectPackage,
	                    onCancel}) => {


	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))

	useEffect(() => {
		if (packages && packages.length === 1) {
			onSelectPackage(packages[0])
		}
	}, [packages, onSelectPackage])

	let dialogWidth = 500

	return (
		<Container style={{maxWidth: '800px', textAlign: 'center'}}>
			<DialogWithBlurredBackdrop
				open={true}
				maxWidth={"md"}
				fullScreen={isSmallScreen}
			>
				<DialogContent
					sx={{
						width: isSmallScreen ? "auto" : dialogWidth,
						transition: 'width 500ms, height 500ms',
						p: isSmallScreen ? 1 : 4
					}}
				>
					<Box display="flex" justifyContent="center" pl={3}>
						<BirdsyMiniLogo width={125}/>
					</Box>
					<Box>
						<Box p={2}>
							<Typography variant="h6">{title}</Typography>
							<Typography variant="p">Choose one to continue</Typography>
						</Box>
						<SelectPackageList relatedPackages={packages}
						                   onSelectPackage={onSelectPackage}
						/>
						{
							onCancel &&
							<Box mt={2}>
								<Link sx={{cursor: 'pointer'}}
								      onClick={onCancel}>
									Close
								</Link>
							</Box>
						}
					</Box>
				</DialogContent>
			</DialogWithBlurredBackdrop>
		</Container>
	)
}

export default SelectPackage
