import {Link, Outlet, useLocation, useNavigate}                         from 'react-router-dom'
import {logger}                                                         from "../../util/log-utils"
import Grid                                                                                 from "@mui/material/Unstable_Grid2"
import {Box, Button, Chip, Container, Divider, IconButton, Tab, Tabs, Typography, useTheme} from "@mui/material"
import {ArrowBack, Edit}                                                                    from "@mui/icons-material"
import {ToggleButton, ToggleButtonGroup} from "@mui/material"
import React, {useState}                 from "react"
import LeftRight                         from "../LeftRight"
import WorkingOverlay from "../WorkingOverlay"

const log = logger("ProductLayout", 1)

function ProductLayout({ product, reloadProduct, onEdit }) {

	const theme = useTheme()
	const navigate = useNavigate()
	const location = useLocation()
	const [working, setWorking] = useState(false)
	const tabs = {
		questions: "Questions",
		media: "Media",
		cost: "Cost" ,
	}

	// Get the last part of the path as the current tab
	const pathSegments = location.pathname.split('/')
	const currentTab = pathSegments.find(segment => tabs[segment]) || "questions"

	const handleTabChange = (event, newTab) => {
		if (newTab !== "questions") {
			navigate(`/admin/products/${product.id}/${newTab}`)
		}
		else {
			navigate(`/admin/products/${product.id}`)
		}
	}

	const handleStartWorking = () => {
		setWorking(true)
	}

	const handleStopWorking = () => {
		setWorking(false)
	}

	return <Grid xs={12} container sx={{px: 0}} spacing={0}>
		{product && <Grid xs={12} sx={{pt: 4}}>
			<Box xs={12}
			     display="flex"
			     flexDirection="row"
			     justifyContent="space-between"
			     alignItems="center"
			     p={0}
			     mb={1}
			>
				<Box style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "flex-start"
				}}>
					<IconButton onClick={() => navigate(`/admin/products`)} sx={{mr: 1}}>
						<ArrowBack/>
					</IconButton>
					<Box>
						<Box display={"flex"} flexDirection={"row"}>
							<Typography variant={"h5"}>
								{product.name}
							</Typography>

						</Box>

						<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>

							<Box sx={{ mr: 1 }}>
								<Typography
									sx={{
										background: `linear-gradient(90deg, ${product.category.color}, ${product.category.color2})`,
										WebkitBackgroundClip: 'text',
										WebkitTextFillColor: 'transparent',
										fontWeight: 'bold',
									}}
								>
									{product.category.name}
								</Typography>
							</Box>
							<Typography>
								{product.short_name && `/ ${product.short_name}`} / {product.token}
							</Typography>
						</Box>

					</Box>
				</Box>

				<Box display={"flex"} flexDirection={"row"} alignItems={"center"} mr={2}>
					<Tabs
						color="primary"
						value={currentTab}
						exclusive="true"
						onChange={handleTabChange}
					>
						{
							Object.keys(tabs).map(k => {
								return <Tab key={k} value={k} label={tabs[k]}/>
							})
						}
					</Tabs>
					<Button variant="contained" onClick={onEdit} color={"primary"}>
						Edit Product
					</Button>
				</Box>
			</Box>
			<Divider/>
		</Grid>
		}
		<Grid xs={12} sx={{
				backgroundColor: theme.palette.background.paper,
				p: 4
			}}
		>
			<Outlet context={{
				product,
				reloadProduct,
				working,
				onStartWorking: handleStartWorking,
				onStopWorking: handleStopWorking
			}} />
		</Grid>
		<WorkingOverlay open={working} />
	</Grid>
}

export default ProductLayout
