import React, {useEffect, useRef, useState}                                                 from 'react'
import TextFormatter                                                                        from "../../TextFormatter"
import {isUserMessage}                                                                      from "../../../util/prepkit-utils"
import {Avatar, Box, IconButton, useTheme}                                                  from "@mui/material"
import {Adjust, BookmarkBorder, BookmarkBorderOutlined, BookmarkOutlined, Circle, MoreVert} from "@mui/icons-material"
import moment                                                                               from "moment"
import PulsingCircle                                                                        from "./PulsingCircle"
import Markdown                                                                             from 'react-markdown'
import StreamingMarkdown                                                                    from "./StreamingMarkdown"

const Message = React.forwardRef(({
	                                  message,
	                                  bookmarked,
									  showBookmark,
	                                  onToggleBookmark,
	                                  onAnimationComplete,
	                                  onTypingComplete,
	                                  isLastMessage,
	                                  elevate,
	                                  onTextTyped
									},
	                                ref // necessary for React.forwardRef
                                  ) => {

	const theme = useTheme()
	const messageRef = useRef(null)
	const [isBookmarked, setIsBookmarked] = useState(bookmarked)
	const [typedText, setTypedText] = useState('')

	const wrapperClassNames = [
		`message-wrapper`,
		`message-${message.author_type?.toLowerCase()}-wrapper`,
		`message-${message.message_type?.toLowerCase()}-wrapper`,
		`message-${message.message_sub_type?.toLowerCase()}-wrapper`,
		message && isLastMessage ? `magnify` : `dont-magnify`,
		message && elevate ? `elevate` : ``,
	].join(" ")


	const classNames = [
		`message`,
		`message-${message.author_type?.toLowerCase()}`,
		`message-${message.message_type?.toLowerCase()}`,
		`message-${message.message_sub_type?.toLowerCase()}`,
		`message-length-${message.body.length > 100 ? "long" : "normal"}`
	].join(" ")


	useEffect(() => {
		if (message.animate) {
			const messageEl = messageRef.current
			messageEl.classList.add('message-entering')
			messageEl.classList.remove('message-entered')

			setTimeout(() => {
				messageEl.classList.remove('message-entering')
				messageEl.classList.add('message-entered')
				onAnimationComplete && onAnimationComplete(message)
			}, 200)
		}
		else if(message.type) {
			triggerTypingEffect()
		}
	}, [message])

	useEffect(()=>{
		onTextTyped && onTextTyped(typedText)
	}, [typedText])


	const triggerTypingEffect = () => {
		let index = 0
		const words = message.body.split(' ')
		const interval = setInterval(() => {
			if (index < words.length) {
				if (words[index]) {
					const w = words[index]
					setTypedText((prev) => {
						return prev + ' ' + w
					})
				}
				index++
			}
			else {
				clearInterval(interval)
				onTypingComplete && onTypingComplete(message)
			}
		}, 75)
	}

	return (
		<Box ref={messageRef} className={wrapperClassNames} border={"0px solid orange"}>
			<Box className={classNames}  border={"0px solid yellow"}>
				{
					showBookmark && // if a handler is passed, then show button
						<Box style={{
							float: 'right',
							marginTop: '-0px',
							marginRight: '-20px'
						}}>
							<IconButton size="small"
							            disabled={!onToggleBookmark}
										onClick={() => {
											if(onToggleBookmark) {
												setIsBookmarked(!bookmarked)
												onToggleBookmark(message)
											}
										}}
							>
								{ isBookmarked
									? <BookmarkOutlined sx={{fontSize: 'x-large', color: 'rgb(225,0,0)'}}/>
									: <BookmarkBorderOutlined sx={{fontSize: 'x-large', color: 'rgba(0,0,0,.25)'}}/>
								}
							</IconButton>
						</Box>
				}

				<Box display="flex" justifyContent="flex-start" alignItems="flex-start" border={"0px solid red"}>
				{
					!isUserMessage(message) &&
						<Avatar src="/birdsy-avatar-black.png"
						        sx={{
									border: '1px solid ' + theme.palette.divider,
							        padding: '2px',
							        width: 30,
							        height: 30,
							        mr: 2,
							        ml: 0,
							        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)'
								}}/>
				}

				{
					isUserMessage(message)
						? <TextFormatter
							text={message.type ? typedText : message.body}
						  />
						: <StreamingMarkdown className={`markdown`}
						                     text={message.type ? typedText : message.body}
						                     streaming={message.streaming}
						  />
				}
				</Box>
			</Box>
			{/*[{message && moment(message.created_at).format('hh:mm:ss a')}]*/}
		</Box>
	)
})

export default Message
