import React, {useEffect, useState}                                                              from 'react'
import {
	Backdrop,
	Box,
	Container,
	Dialog,
	DialogContent,
	useTheme,
	useMediaQuery,
	DialogActions,
	Link, Button, Divider
} from "@mui/material"
import getSupabase from "../../util/supabase-utils"
import BirdsyMiniLogo                                                               from "../BirdsyMiniLogo"
import Login                                                                        from "./Login"
import SignUp                                                                       from "./SignUp"
import ForgotPassword                                                               from "./ForgotPassword"
import styled                                                                       from "@emotion/styled"
import UpdatePassword                                                               from "./UpdatePassword"
import { useUserStore }                                                             from "../../state"
import MiniSkeleton                                                                 from "../mini/MiniSkeleton"
import AuthIntro                from "./AuthIntro"
import {useNavigate, useParams} from "react-router-dom"
import LeftRight                  from "../LeftRight"
import {ArrowBack, Help, Support} from "@mui/icons-material"

function BirdsyAuth() {

	const theme = useTheme()
	const navigate = useNavigate()
	const { view: routeView } = useParams()
	const [currentView, setCurrentView] = useState(routeView || '')
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

	const switchView = (view) => {
		setCurrentView(view)
	}

	const launchSupportEmail = () => {
		const currentUrl = window.location.href
		const body = `


------------------------------------------
Page: ${currentUrl}
		`
		const mailtoLink = `mailto:support@birdsy.ai?subject=Help Signing In&body=${encodeURIComponent(body)}`
		window.location.href = mailtoLink
	}

	return (
		<Container
			style={{
				maxWidth: isSmallScreen ? '100%' : isMediumScreen ? '?600px' : '800px',
				textAlign: 'center',
				padding: isSmallScreen ? '0 16px' : '0'
			}}
		>
			{
				!isSmallScreen && !isMediumScreen && <MiniSkeleton />
			}
			<Dialog open={true} fullScreen={isSmallScreen}>
				<DialogContent>
					<Box display="flex" justifyContent="center" pb={2}>
						<BirdsyMiniLogo width={isSmallScreen ? 100 : 125} />
					</Box>
					{currentView === '' && <AuthIntro switchView={switchView} />}
					{currentView === 'login' && <Login switchView={switchView} />}
					{currentView === 'forgotPassword' && <ForgotPassword switchView={switchView} />}
					{currentView === 'signUp' && <SignUp switchView={switchView} />}
					{currentView === 'update' && <UpdatePassword switchView={switchView} />}
				</DialogContent>
				<Divider/>
				<Box flexDirection={"row"}
				     display={"flex"}
				     justifyContent={"center"}
				     alignItems={"center"}
				     backgroundColor={theme.palette.divider}
				>
					<Button sx={{cursor: 'pointer'}} onClick={()=>navigate('/prep')}>
						Home
					</Button>
					<Button sx={{cursor: 'pointer'}} onClick={launchSupportEmail}>
						Help
					</Button>
				</Box>
			</Dialog>
		</Container>
	)
}

export default BirdsyAuth
