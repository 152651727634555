import React, {useContext, useEffect, useMemo, useState}                          from 'react'
import {useNavigate, useParams}                                                   from 'react-router-dom'
import {useUserStore}                                                             from "../../state"
import {logger}                                                                   from "../../util/log-utils"
import {
	Box,
	Container,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemButton,
	Tab,
	Tabs,
	Typography,
	useMediaQuery,
	useTheme
}                                                                                 from "@mui/material"
import {CircleOutlined, Close}                                                    from "@mui/icons-material"
import Grid                                                                       from "@mui/material/Unstable_Grid2"
import {MiniPrepKitContext}                                                       from "./MiniPrepKit"
import SectionCard                                                                from "./SectionCard"
import {fetchUserProfile, findFirstQuestionInTopic, findMissedQuestionsAndTopics} from "../../util/mini"
import SlideView                                                                  from "./SlideView"
import LeftRight                                                                  from "../LeftRight"
import Welcome                                                                    from "./Welcome"
import Tour                                                                       from "./Tour"
import {Helmet}                                                                   from "react-helmet-async"


const log = logger("MiniPrepKitDashboard", 1)

const MiniPrepKitDashboard = React.memo(() => {
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const navigate = useNavigate()
	const {topicId} = useParams()
	const {user} = useUserStore()
	const prepKit = useContext(MiniPrepKitContext)
	const [selectedProduct, setSelectedProduct] = useState(prepKit && prepKit.products && prepKit.products[0])
	const [incorrectQuestionsSection, setIncorrectQuestionsSection] = useState(null)
	const [userProfile, setUserProfile] = useState(null)
	const [welcomeComplete, setWelcomeComplete] = useState(false)

	const showWelcome = useMemo(()=>{
		return prepKit &&
			userProfile &&
			!userProfile.welcomed_at &&
			!welcomeComplete
		}, [prepKit, userProfile, welcomeComplete])

	useEffect(() => {
		console.log(`useEffect[user=${user?.id}, welcomeComplete=${welcomeComplete}]`)
		if (user) {
			const fetchProfile = async () => {
				const profile = await fetchUserProfile(user.id)
				setUserProfile(profile)
			}
			fetchProfile()
		}
	}, [user, welcomeComplete])

	const missedQuestionsByTopic = useMemo(() => {
		if (incorrectQuestionsSection) {
			const tops = findMissedQuestionsAndTopics(incorrectQuestionsSection)
			return tops
		}
		else {
			return null
		}
	}, [incorrectQuestionsSection])


	const viewIndex = useMemo(() => {
		return topicId ? 1 : 0
	}, [topicId])

	const handleSectionStart = (topic) => {
		const question = findFirstQuestionInTopic(topic)
		//console.log({question})
		question && navigate(`q/${question.code}`)
	}

	const handleSectionResume = (topic) => {
		const question = findFirstQuestionInTopic(topic, (q) => !q.userAnswer)
		//console.log({question})
		question && navigate(`q/${question.code}`)
	}

	const topicView = (
		<Container
			disableGutters={true}
			sx={{border: "0px solid blue"}}
			maxWidth={"md"}
		>
			{/*<Section prepKit={prepKit} topic={selectedTopic} onBack={()=>navigate('..')}/>*/}
		</Container>
	)

	const sectionView = (
		<Container
			disableGutters={true}
			sx={{
				border: "0px solid red",
			}}
			maxWidth={"md"}
		>
			<Helmet>
				<title>{prepKit && `PrepKit Dashboard - ${prepKit.name} - Birdsy`}</title>
			</Helmet>
			<Grid xs={12} pb={isSmallScreen ? 0 : 2} sx={{border: "0px solid magenta"}}>
				<Box display={"flex"}
				     flex={1}
				     flexDirection={"column"}
				     id={`tour-prepkit-title`}
				>
					<Typography variant={isSmallScreen ? `h6` : `h4`}
					            sx={{textAlign: 'center'}}
					>
						{prepKit && prepKit.name}
					</Typography>
					{
						showWelcome
						? <Typography variant={isSmallScreen ? `body2` : `h6`}
						              sx={{textAlign: 'center'}}
						              color={"secondary"}
						>
							Welcome to Birdsy!
						</Typography>
						: <Typography variant={isSmallScreen ? `body2` : `h6`}
						              sx={{textAlign: 'center'}}
						              color={"secondary"}
						  >
							Start or resume a study session.
						  </Typography>
					}
					{
						isSmallScreen && <Divider sx={{mt: 2}}/>
					}
				</Box>
			</Grid>
			{
				showWelcome &&
				<Grid spacing={isSmallScreen ? 0 : 3}
				      justifyContent="flex-start"
				      py={isSmallScreen ? 0 : 3}
				>
					<Welcome
						prepKit={prepKit}
						user={user}
						userProfile={userProfile}
						onComplete={(up) => {
							setUserProfile(up)
							setWelcomeComplete(true)
						}}
					/>
				</Grid>
			}
			{
				!showWelcome &&
				<Grid spacing={isSmallScreen ? 0 : 3}
				      justifyContent="flex-start"
				      py={isSmallScreen ? 0 : 3}
				>
					{
						prepKit &&
						prepKit.products &&
						prepKit.products.length > 1 &&
						<Box
							display={"flex"}
							flex={1}
							justifyContent={"center"}
						>
							<Tabs
								id={`tour-product-tabs`}
								value={selectedProduct && selectedProduct.id}
								sx={{
									border: `1px solid ${theme.palette.divider}`,
									borderRadius: 10,
									px: 2
								}}
								TabIndicatorProps={{
									style: {
										display: 'none'
									}
								}}
							>
								{
									prepKit.products.map((p, i) => (
										<Tab key={`tab-${p.id}`}
										     value={p.id}
										     label={p.short_name}
										     onClick={() => setSelectedProduct(p)}
										     sx={{
											     borderLeft: i===0 ? 'none' : `1px solid ${theme.palette.divider}`,
											     px: 3
										     }}
										/>
									))
								}
							</Tabs>
						</Box>
					}
				</Grid>
			}
			{
				!showWelcome &&
				selectedProduct &&
				<Grid id={"tour-sections"}
				      xs={12}
				      xsOffset={0}
				      lg={10}
				      lgOffset={1}
				      spacing={0}
				      container
				      key={`product-${selectedProduct.id}`}
				      sx={{border: "0px solid red"}}
				>
					{/*
					<Grid xs={12} sx={{border: "0px solid orange"}}>
						<Typography variant={"h5"}
						            sx={{my: 2}}
						>
							{selectedProduct.name}
						</Typography>
					</Grid>
					*/}
					{
						selectedProduct.topics.map((t,i) => {
							return (
								<Grid key={`section-${t.id}`}
								      id={`tour-section-${i}`}
								      xs={12} sm={12} md={12}
								      sx={{
										  border: "0px solid purple",
									      my: 0,
									      px: isSmallScreen ? 2.5 : 0,
									      py: 1,
									      borderBottom: i < selectedProduct.topics.length - 1
										      ? `1px solid ${theme.palette.divider}`
										      : 'none'
									  }}
								>
									<SectionCard
										section={t}
										index={i}
										onClickStart={handleSectionStart}
										onClickRestart={handleSectionStart}
										onClickResume={handleSectionResume}
									/>
								</Grid>
							)
						})
					}
				</Grid>
			}
			<Drawer
				open={Boolean(incorrectQuestionsSection)}
				anchor={"bottom"}
				PaperProps={{
					sx: {
						height: '80vh',
						width: '100%',
						maxWidth: {
							xs: '100vw',
							sm: '100vw',
							md: '960px',
							lg: '960px',
							xl: '960px',
						},
						margin: '0 auto',
						borderTopLeftRadius: 15,
						borderTopRightRadius: 15,
					},
				}}
			>
				<Box
					sx={{
						position: 'sticky',  // Stays fixed at the top of the drawer
						top: 0,
						zIndex: 1000,
						backgroundColor: 'white',
						padding: 2,
						borderBottom: '0px solid #ccc',
					}}
				>
					<LeftRight
						left={
							<Typography variant={"h6"}>
								Missed Questions in {incorrectQuestionsSection && incorrectQuestionsSection.name}
							</Typography>
						}
						right={
							<IconButton onClick={() => setIncorrectQuestionsSection(null)}>
								<Close color={"error"}/>
							</IconButton>
						}
					/>
				</Box>

				<Box p={3}>
					{
						missedQuestionsByTopic &&
						missedQuestionsByTopic.map(o => (
							<Box key={`missed-topic-${o.topic.id}`}>
								<Typography color={"secondary"}>{o.topic.name}</Typography>
								<List sx={{mb: 2}}>
									{
										o.questions.map(q => (
											<ListItemButton
												key={`missed-question-${q.id}`}
												onClick={() => navigate(`q/${q.code}`)}
											>
												<CircleOutlined fontSize={"14"} sx={{mr: 1, opacity: .5}}/>
												{q.question}
											</ListItemButton>
										))
									}
								</List>
							</Box>
						))
					}
				</Box>
			</Drawer>
			{
				!showWelcome &&
				<Tour token={"prepkit-dashboard"} last={"Continue"} />
			}
		</Container>
	)

	const views = [sectionView, topicView]


	return <SlideView
		index={viewIndex}
		views={views}
	/>

})

export default MiniPrepKitDashboard
