import React                                               from "react"
import {useNavigate, useParams}                            from "react-router-dom"
import {Alert, AlertTitle, Box, Container, Link, useTheme} from "@mui/material"
import Grid                                                from "@mui/material/Unstable_Grid2"
import {logger}                                            from "../../util/log-utils"
import "../../stylesheets/MiniQuestionChat.css"
import QuestionCode                                        from "./QuestionCode"
import useQuery                                            from "../../hooks/useQuery"

const log = logger("MiniCodeView", 1)

function MiniCodeView({ toggleTheme }) {
	const theme = useTheme()
	const navigate = useNavigate()
	const { err: badCode } = useQuery()
	const { code: defaultCode } = useParams()

	console.log({badCode})

	return (
		<Container style={{ maxWidth: '800px', marginTop: "-80px", textAlign: 'center', height: '100vh', display: 'flex'  }}>
			<Grid container justifyContent="center" sx={{height: "100%", border: "0px solid purple"}}>
				<Box height={'100%'}
				     display={"flex"}
				     flexDirection={"column"}
				     justifyContent={"center"}
				     alignItems={"center"}
				>
					<h2 style={{marginRight: 1, fontWeight: "normal", color: theme.palette.text.disabled}}>
						Enter Question Code
					</h2>
					<QuestionCode
						defaultCode={badCode || defaultCode}
						autoSubmit={!badCode}
						onSubmit={(code)=>{
							navigate(`/q/${code}`)
						}}
					/>
					{
						badCode &&
						<Alert severity={"warning"} sx={{mt: 2, textAlign: "left"}}>
							<AlertTitle>Question Code</AlertTitle>
							Question code {badCode} not found.  Please try again.
						</Alert>
					}
					<Box>
						<Box mt={9} color={theme.palette.text.secondary}>
							No question code?  <Link href="/prep">Explore Birdsy &rarr;</Link>
						</Box>
					</Box>
				</Box>
			</Grid>
		</Container>
	)
}

export default React.memo(MiniCodeView)
