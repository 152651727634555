import React, {useEffect, useState}   from 'react'
import {Alert, AlertTitle, Container} from "@mui/material"
import moment                                           from "moment"
import {doGet}                  from "../../util/do-fetch"
import {useNavigate, useParams} from "react-router-dom"
import MiniPackage              from "./MiniPackage"


const RenewPrepKit = () => {
	const {id} = useParams()
	const navigate = useNavigate()
	const [prepKit, setPrepKit] = useState(null)
	const [error, setError] = useState(null)

	useEffect(() => {
		if(id) {
			const fetchPrepKit = async () => {
				if (!prepKit || prepKit.id !== id) {
					const response = await doGet(`/mini/prepkit/${id}/summary`)
					if (response.ok) {
						const data = await response.json()

						//todo: make sure it is still expired, its possible that the user
						// is at this URL and has since renewed, but when they hit refresh
						// on this page it still shows renew option because that is what this
						// url is mapped to (/renew/id)...
						if (data.expires_at &&
							moment(data.expires_at).isAfter(moment().add(48, 'hours'))) {
							// If the prep kit is NO LONGER EXPIRED, redirect to the dashboard
							// this is meant to handle if they just renewed, but wound up back at this page
							// to avoid confusion.... hence the check to make sure it doesn't expire for at least
							// 48 hours
							navigate(`/prepkit/${id}`)
						}
						else {
							setPrepKit(data)
						}
					}
					else if (response.status === 404) {
						setError("PrepKit not found.")
					}
				}
			}
			fetchPrepKit()
		}
	}, [id, prepKit])


	if (!prepKit) return null

	return (
		<Container maxWidth="lg">
			{
				error &&
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{error}
				</Alert>
			}
			<MiniPackage packageToken={prepKit.package.token}
			             categoryToken={prepKit.package.category.token}
			             prepKit={prepKit}
			/>
		</Container>
	)
}

export default RenewPrepKit
