import ReactGA from 'react-ga4'

const enabled = process.env.NODE_ENV !== "development"

export const initializeGA = (gtag) => {
	enabled && ReactGA.initialize(gtag)
}

export const setUserId = (userId) => {
	enabled && ReactGA.set({
		user_id: userId || null,         // Null for logged-out users
		is_logged_in: !!userId          // Boolean indicating logged-in state
	})
}

export const trackPageView = (path) => {
	enabled && ReactGA.send({ hitType: 'pageview', page: path })
}

export const trackViewItem = (selectedPackage, selectedOption) => {
	enabled && ReactGA.event('view_item', {
		items: [
			{
				item_id: selectedPackage.id,
				item_name: selectedPackage.name && (selectedOption && (' - ' + selectedOption.name)),
				item_category: selectedPackage.category ? selectedPackage.category.name : 'null'
			}
		]
	})
}

export const trackBeginCheckout = (stripeId, selectedPackage, selectedOption) => {
	if(enabled) {
		const price = selectedOption.price / 100
		ReactGA.event('begin_checkout', {
			transaction_id: stripeId,
			currency: 'USD',
			value: price,
			items: [
				{
					item_id: selectedPackage.id, // Focus on the package ID
					item_name: selectedPackage.name, // Focus on the package name
					item_variant: selectedOption.name, // Include the option as a secondary detail
					item_category: selectedPackage.category ? selectedPackage.category.name : 'null',
					price: price,
					quantity: 1
				}
			]
		})
	}
}

export const trackPurchase = (paymentIntentId, selectedPackage, selectedOption) => {
	if(enabled) {
		const price = selectedOption.price_in_cents / 100
		ReactGA.event('purchase', {
			transaction_id: paymentIntentId,
			value: price,
			currency: 'USD',
			items: [{
				item_id: selectedPackage.id, // Focus on the package ID
				item_name: selectedPackage.name, // Focus on the package name
				item_variant: selectedOption.name, // Include the option as a secondary detail
				item_category: selectedPackage.category ? selectedPackage.category.name : 'null',
				price: price,
				quantity: 1
			}]
		})
	}
}

export const trackPurchaseFromLicense = (license, categoryName) => {
	if(enabled) {
		const stripeId = license.checkout_session_id || license.payment_intent_id
		const price = license.package_price_in_cents / 100
		ReactGA.event('purchase', {
			transaction_id: stripeId,
			value: price,
			currency: 'USD',
			items: [{
				item_id: license.package_id, // Focus on the package ID
				item_name: license.package_name, // Focus on the package name
				item_variant: license.package_option_name, // Include the option as a secondary detail
				item_category: categoryName || 'null',
				price: price,
				quantity: 1
			}]
		})
	}
}
