import React, {createContext, useEffect, useMemo, useRef, useState} from 'react'
import {Outlet, useNavigate, useParams}                             from 'react-router-dom'
import {doGet}                                                      from "../../util/do-fetch"
import {getSupabase}                                                from "../../util/supabase-utils"
import {useUserStore}                                               from "../../state"
import {DEBUG, logger}                                              from "../../util/log-utils"
import {Alert, AlertTitle, Box, Button, Typography}                 from "@mui/material"
import {ArrowBack}                                                  from "@mui/icons-material"
import ExpiringPrepKit                                              from "./ExpiringPrepKit"
import {findQuestionInPrepKit, scorePrepKit}                        from "../../util/mini"
import LoadingDots                                                  from "../LoadingDots"
import SelectPackage                                                from "./SelectPackage"
import {cloneDeep}                                                  from "lodash"
import TryPackage                                                   from "./TryPackage"
import {fetchTryPackageUrl, isExpired}                              from "../../util/prepkit-utils"

const MiniPrepKitContext = createContext()

const log = logger("MiniPrepKit", DEBUG)

const MiniPrepKit = () => {
	const navigate = useNavigate()
	const {id, code} = useParams()
	const {user} = useUserStore()
	const [prepKit, setPrepKit] = useState(null)
	const [packages, setPackages] = useState(null)
	const [trialPackages, setTrialPackages] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const prepKitRef = useRef(null)

	const expired = useMemo(()=>{
		return isExpired(prepKit)
	}, [prepKit])

	useEffect(() => {
		prepKitRef.current = prepKit;
	}, [prepKit]);

	useEffect(() => {
		if (expired) {
			navigate(`/renew/${id}`, { replace: true })
		}
	}, [id, expired, navigate])

	// subscribe to answer channel
	useEffect(() => {
		if (user && prepKit) {
			const subscribeToAnswerChannel = () => {
				if (!user) return null

				const channelName = `channel-answer-${user.id}`
				console.log(`subscribing to ${channelName}`)
				const supabase = getSupabase()
				return supabase
					.channel(channelName)
					.on(
						"postgres_changes",
						{
							event: "*",
							schema: "public",
							table: "answer",
							filter: `user_id=eq.${user.id}`,
						},
						(event) => {
							log.debug(`${channelName} ${event.eventType} subscribe answer ${event.new.id}`)
							if (event.eventType === "INSERT" ||
								event.eventType === "UPDATE"
							) {
								const newAnswer = event.new

								// Use the ref to access the latest prepKit
								const currentPrepKit = prepKitRef.current;

								if (!currentPrepKit) {
									console.log("no prepKit, bailing");
									return
								}

								if (currentPrepKit.id !== newAnswer.prepkit_id) {
									console.log(`id[${currentPrepKit.id}] !== newId[${newAnswer.prepkit_id}], bailing`);
									return
								}

								setPrepKit(prev => {
									const prepKitX = cloneDeep(prev)

									// update answer array in the prepKit
									const answerIndex = prepKitX.answers.findIndex(a => a.id === newAnswer.id)
									if (answerIndex !== -1) {
										// answer with same id exists
										prepKitX.answers[answerIndex] = newAnswer  // update the answer at the found index
									}
									else {
										// no answer with the same id
										log.debug("did not find matching answer, append to answers")
										prepKitX.answers.push(newAnswer)
									}

									// find the question and update it's answer
									const { question } = findQuestionInPrepKit(prepKitX, newAnswer.question_id)
									question.userAnswer = newAnswer

									// re-score the prepKit to account for new/updated answer
									// NOTE: it is important to note that any objects updated by scoring should have been
									// already cloned above as part of updating the answer state
									scorePrepKit(prepKitX)
									return prepKitX
								})
							}
						}
					)
					.subscribe()
			}

			const answerChannel = subscribeToAnswerChannel();

			return () => {
				if (answerChannel) {
					log.debug("unsubscribe from answerChannel");
					answerChannel.unsubscribe();
				}
			}
		}
	}, [prepKit, user])

	useEffect(() => {
		log.debug(`useEffect[id,prepKit,code] ${id}/${code}`)
		if(id) {
			const fetchPrepKit = async () => {
				if (!prepKit || prepKit.id !== id) {
					setLoading(true)
					setTrialPackages(null)
					setPackages(null)
					// only fetch the prepKit data if not loaded
					const response = await doGet(`/mini/prepkit/${id}`)

					if (response.ok) {
						const data = await response.json()
						scorePrepKit(data) // important to do this before you set local state
						setPrepKit(data)
					}
					else if (response.status === 404) {
						setError("PrepKit not found.")
					}

					setLoading(false)
				}
			}
			fetchPrepKit()
		}
		else if(code) {
			const checkAccess = async () => {
				// get the package/license for this question and user
				try {
					const url = `/mini/check-access/${code}`
					const response = await doGet(url)
					if (response.ok) {
						const data = await response.json()
					    if (data.prepKit) {
						    setTrialPackages(null)
						    setPackages(null)
							navigate(`/prepkit/${data.prepKit.id}/q/${code}`)
						}
						else if (data.trialPackages) {
							// if there are other packages available, then offer them
							setTrialPackages(data.trialPackages)
						}
						else if (data.prepKits) {
							// show modal so user can choose which prepKit
							// this is a RARE possibility
						    console.log('show modal so user can choose which prepKit')
						}
					}
					else if (response.status == 402) { // payment required
						// need to buy a license
						// or renew a license
						const data = await response.json()
						setPackages(data.packages)
					}
					else if (response.status == 404) { // question not found
						// need to buy a license
						// or renew a license
						navigate(`/q?err=${code}`)
					}
				}
				catch (err) {
					log.error(err)
				}
			}
			checkAccess()
		}
	}, [id, prepKit, code, navigate])


	const handleTryPackage = async (packageToTry) => {
		try {
			const url = await fetchTryPackageUrl(packageToTry, code)
			navigate(url)
		}
		catch (err) {
			log.error(err)
			setError(err)
		}
	}

	const handleSetExpired = () => {
		navigate(`/renew/${id}`, { replace: true })
	}

	let comp = null

	if(loading) {
		comp = (
			<Box pt={10}>
				<LoadingDots/>
			</Box>
		)
	}
	else if(error) {
		comp = (
			<Box pt={10} width={"100%"} maxWidth={"600px"} margin={"0 auto"}>
				<Alert severity="error">
					<AlertTitle>
						<Typography variant={"h5"}>Error</Typography>
					</AlertTitle>
					<Typography variant={"h6"}>{error}</Typography>
					<Button onClick={()=>navigate('/prepkit')}>
						<ArrowBack/> Back to PrepKits
					</Button>
				</Alert>
			</Box>
		)
	}
	else if(packages) {
		comp = (
			<Box textAlign="center">
				{/* we may need to show available packages because user may have  */}
				<SelectPackage title="Purchase PrepKit"
				                 packages={packages}
				                 onCancel={()=>{
					                 navigate('..')
				                 }}
				                 onSelectPackage={(p)=>navigate(`/prep/${p.category.token}/${p.token}`)}
				/>
			</Box>
		)
	}
	else if(trialPackages) {
		comp = (
			<Box textAlign="center">
				<TryPackage packages={trialPackages}
				            onTryPackage={handleTryPackage}
				            onCancel={()=>{
					            navigate('/prep')
				            }}
				/>
			</Box>
		)
	}
	else {
		comp = <Outlet />
	}

	return (
		<MiniPrepKitContext.Provider value={prepKit}>
			<Box>
				{comp}
			</Box>

			<ExpiringPrepKit prepKit={prepKit}
			                 onBuy={handleSetExpired}
			                 onPrepKitExpired={handleSetExpired}
			/>

		</MiniPrepKitContext.Provider>
	)
}

export default MiniPrepKit
export {MiniPrepKitContext}
