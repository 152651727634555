import { create }      from 'zustand'
import { getSupabase } from '../util/supabase-utils'
import {logger}        from "../util/log-utils"

const log = logger("useUserStore", 1)

export const useUserStore = create((set, get) => {
	const supabase = getSupabase()

	// Initialize the session from Supabase on startup
	const initializeSession = async () => {
		try {
			const { data, error } = await supabase.auth.getSession()
			if (error) {
				log.error("Error initializing session:", error)
				return
			}
			if (data.session) {
				set({ session: data.session, user: data.session.user })
			} else {
				set({ session: null, user: null })
			}
		} catch (err) {
			log.error("Exception during session initialization:", err)
		}
	}

	// Listen for auth state changes
	const { data: subscription } = supabase.auth.onAuthStateChange((event, session) => {
		log.debug(`supabase.auth.onAuthStateChange ${event}`)
		const oldUser = get().user

		if (event === 'SIGNED_OUT') {
			if (oldUser) {
				set({ session: null, user: null })
			}
		} else if (event === 'SIGNED_IN') {
			const newUser = session?.user
			if (!oldUser || oldUser.id !== newUser?.id) {
				set({ session, user: newUser })
			}
		}
	})

	// Initialize the session on startup
	initializeSession()

	return {
		session: null,
		user: null,
		setSession: (session) => set(() => ({ session, user: session?.user })),
		signOut: async () => {
			const { error } = await supabase.auth.signOut()
			if (error) {
				log.error("Error signing out:", error)
			} else {
				set({ session: null, user: null })
			}
		},
		cleanup: () => {
			subscription.unsubscribe() // Ensure subscription cleanup
		}
	}
})
