import {useEffect, useState} from "react"
import {doGet, doPost}       from "../../util/do-fetch"
import Joyride               from "react-joyride"
import {useTheme}            from "@mui/material"


const Tour = ({token, last}) => {

	const theme = useTheme()
	const [steps, setSteps] = useState(null)

	useEffect(()=>{
		const fetchSteps = async () => {
			try {
				const response = await doGet(`/mini/tour/${token}`)
				if(response.ok) {
					const tour = await response.json()
					setSteps(tour && tour.steps)
				}
				else {
					setSteps(null)
				}
			}
			catch (err) {
				setSteps(null)
			}
		}
		fetchSteps()
	}, [token])

	const markTourComplete = async () => {
		try {
			await doPost(`/mini/tour/${token}`)
		}
		catch (err) {
			setSteps(null)
		}
	}

	const handleJoyrideCallback = async (data) => {
		const { type } = data

		if(type==="tour:end") {
			await markTourComplete()
		}
	}

	return steps &&
		<Joyride
			steps={steps}
			run={true}
			disableScrollParentFix={true}
			disableOverlayClose={true} // Prevent skipping steps by clicking outside
			spotlightClicks={true} // Allow clicks on highlighted elements
			continuous={true}
			callback={handleJoyrideCallback}
			showSkipButton={true}
			styles={{
				options: {
					zIndex: 10000, // Ensure the tour is above other components
				},
				buttonNext: {
					backgroundColor: theme.palette.secondary.main, // Use theme's success color for "Next" button
					color: theme.palette.secondary.contrastText, // Ensure text is readable
				},
				buttonBack: {
					color: theme.palette.secondary.main, // Use theme's success color for "Back" button
				},
				buttonSkip: {
					backgroundColor: theme.palette.secondary.main, // Use theme's success color for "Skip" button
					color: theme.palette.secondary.contrastText, // Ensure text is readable
				},
			}}
			locale={{
				last: last || "Done"
			}}
		/>
}

export default Tour