import {uuid}          from "@supabase/supabase-js/dist/main/lib/helpers"
import {doGet, doPost} from "./do-fetch"
import moment          from "moment"
import {scorePrepKit}  from "./mini"

const fetchPrepKit = async (preKitId, includePackage) => {
	if (preKitId) {
		// only fetch the prepKit data if not loaded
		const query = includePackage ? '?includeLicenseAndPackage=true' : ''
		const response = await doGet(`/mini/prepkit/${preKitId}${query}`)

		if (response.ok) {
			const data = await response.json()
			scorePrepKit(data) // important to do this before you set local state

			return data
		}
		else if (response.status === 404) {
			throw "PrepKit not found."
		}
	}
}

const fetchTryPackageUrl = async (packageToTry, questionCode) => {
	const response = await doPost(`/mini/package/${packageToTry.token}/try`)

	if (response.ok) {
		const data = await response.json()
		const prepKit = data.prepKit
		if(prepKit) {
			return `/prepkit/${prepKit.id}` + (questionCode && `/q/${questionCode}`)
		}
		else {
			throw `Your complimentary study session has expired.`
		}
	}
	else if (response.status === 403) {
		// forbidden
		throw `Your complimentary study session has expired.`
	}
}

const createQueuedUserMessage = (body, animate, type, messageSubType) => {
	return {
		id: uuid(), // it is important that the client creates the id
		body: body,
		message_type: "TEXT",
		message_sub_type: messageSubType,
		author_type: "USER",
		animate: true,
		type: false,
		created_at: new Date()
	}
}

const createQueuedAiMessage = (body, animate, type, messageSubType) => {
	return {
		id: uuid(), // it is important that the client creates the id
		body: body,
		message_type: "TEXT",
		message_sub_type: messageSubType,
		author_type: "AI",
		animate: animate === true,
		streaming: true,
		type: type === true,
		created_at: new Date()
	}
}

const createStreamingAiMessage = (animate, type, messageSubType) => {
	return {
		id: uuid(), // it is important that the client creates the id
		body: "",
		message_type: "TEXT",
		message_sub_type: messageSubType,
		author_type: "AI",
		streaming: true,
		animate: animate === true,
		type: type === true,
		created_at: new Date()
	}
}

const fmtPath = (path, removeLast, removeFirstDelim, newDelim) => {
	const parts = path ? path.split('/') : []
	removeLast && parts.pop()
	removeFirstDelim && parts.shift()
	return parts.join(newDelim || ' / ')
}

const fmtModels = (models) => {
	const parts = models ? models.split(',') : []
	const unique =	[...new Set(parts)]
	return unique.join(', ')
}

const fmtDttm = (dttm) => {
	return dttm && moment(dttm).format('MMM Do h:mm:ssa')
}

const fmtCal = (dttm) => {
	return dttm && moment(dttm).calendar()
}

const fmtDur = (start, end) => {
	return start && end && (((moment(end).valueOf() - moment(start).valueOf()) / 1000) + 's')
}

const fmt2Dec = (number) => {
	return (Math.round(number * 100) / 100).toFixed(2);
}

const fmtPct = (number) => {
	return Math.round(number * 100) + '%'
}

const fmtMinSec = (millis) => {
	const totalSeconds = Math.floor(millis / 1000)
	const hours = Math.floor(totalSeconds / 3600)
	const minutes = Math.floor((totalSeconds % 3600) / 60)
	const seconds = totalSeconds % 60

	const formattedMinutes = minutes
	const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

	if (hours > 0) {
		return `${hours}h ${formattedMinutes}m ${formattedSeconds}s`
	} else {
		return `${formattedMinutes}m ${formattedSeconds}s`
	}
}

const shortenString = (str, maxLen) => {
	if(!str) return str
	maxLen = maxLen || 400
	if (str.length <= maxLen) return str

	const start = str.substring(0, maxLen / 2 - 1)
	const end = str.substring(str.length - maxLen / 2 + 1)

	return `${start}...${end}`
}

const typeIt = (s, typingSpeed, onType, onComplete) => {
	typingSpeed = !typingSpeed ? 300 : typingSpeed
	const words = s.split(" ")
	const typingInterval = setInterval(() => {
		if (words.length > 0) {
			onType(" " + words.shift())
		}
		else {
			clearInterval(typingInterval)
			if (onComplete) {
				onComplete()
			}
		}
	}, typingSpeed)
	return typingInterval
}

const isUserMessage = (message) => {
	return message &&
		message.author_type === "USER"
}

const isAiMessage = (message) => {
	return message &&
		message.author_type === "AI"
}

const isExpired = (prepKit) => {
	if(!prepKit) return false
	const expiresIn = getExpiresIn(prepKit)
	return Boolean(expiresIn <= 0)
}

const getExpiresIn = (prepKit) => {
	if(!prepKit) return -1
	const now = new Date().getTime()
	const then = new Date(prepKit.expires_at).getTime()
	const expiresIn = then - now
	//console.log({now, then, expiresIn})
	return expiresIn
}

/**
 *
 * @param productOrTopic
 * @returns {null|{questions}|*}
 */
const findFirstTopicWithQuestions = (productOrTopic) => {
	if (productOrTopic.questions && productOrTopic.questions.length) {
		return productOrTopic
	}
	else if (productOrTopic.topics && productOrTopic.topics.length) {
		for (const topic of productOrTopic.topics) {
			const result = findFirstTopicWithQuestions(topic)
			if (result) {
				return result
			}
		}
	}
	return null
}

/**
 *
 * @param productOrTopic
 * @returns {{is_leaf}|*|null|any}
 */
const findFirstLeafTopic = (productOrTopic) => {
	if(productOrTopic.is_leaf) {
		return productOrTopic
	}
	else if (productOrTopic.topics && productOrTopic.topics.length) {
		for (const topic of productOrTopic.topics) {
			if(topic.is_leaf) {
				return topic
			}
		}
	}
	return null
}

/**
 *
 * @param productOrTopic
 * @param topicId
 * @param questionId
 * @returns {null|{question: (*), topic: (any|*)}}
 */
const findTopicAndQuestion = (productOrTopic, topicId, questionId) => {

	const topic = findTopicById(productOrTopic, topicId)

	if(topic) {
		const question = findQuestionInTopic(topic, questionId)
		return {topic, question}
	}
	else {
		return null
	}
}

/**
 *
 * @param parent
 * @param topicId
 * @returns {null|any|*}
 */
const findTopicById = (parent, topicId, depth) => {
	depth = depth || 0
	console.log(`findTopicById(${parent?.id},${topicId}, ${depth})`)
	if(parent.topics && parent.topics.length > 0) {
		for (const topic of parent.topics) {
			if(topic.id == topicId) {
				console.log(`MATCH FOUND: findTopicById(${parent?.id},${topicId}) ${topic.id} == ${topicId}`)
				return topic
			}
			else {
				console.log(`MATCH NOT FOUND: recursing`)
				const result = findTopicById(topic, topicId, depth+1)
				if(result) {
					return result
				}
			}
		}
	}
	return null
}

/**
 *
 * @param topic
 * @param questionId
 * @returns {null|*}
 */
const findQuestionInTopic = (topic, questionId) => {
	console.log(`Entering findQuestionInTopic with topic ID: ${topic.id} and questionId: ${questionId}`);

	if (topic.questions && topic.questions.length > 0) {
		console.log('Searching in questions of the current topic.')
		for (const question of topic.questions) {
			if (question.id == questionId) {
				console.log(`Found question with ID: ${questionId}`)
				return question;
			}
			else {
				console.log(`Comparing question failed: ${question.id} === ${questionId}`);
			}
		}
	} else if (topic.topics && topic.topics.length > 0) {
		console.log('Entering child topics.');
		for (const child of topic.topics) {
			const question = findQuestionInTopic(child, questionId);
			if (question) {
				console.log(`Found question with ID: ${questionId} in child topic.`);
				return question;
			}
		}
	}
	console.log(`Exiting findQuestionInTopic; Did not find question with ID: ${questionId}`);
	return null;
};

/**
 * Returns an prepKit along with topics and product, etc.
 * @param id
 * @returns {Promise<*>}
 */
const findPrepKit = async (id) => {
	const url = `/prepkit/${id}`;
	const response = doGet(url);
	return response.json();
}

/**
 *
 * @param prepKit
 */
const isPrepKitExpired = (license) => {
	const expired = moment(license.expires_at).isBefore(moment())
	return expired
}

/**
 * Returns letter for corresponding answer index.  0=A, 1=B, 2=C, 3=D
 *
 * @param optionIndex
 * @returns {string}
 */
const abcd = (optionIndex) => {
	return ["A", "B", "C", "D"][optionIndex]
}

export {
	fetchPrepKit,
	abcd,
	createQueuedUserMessage,
	createQueuedAiMessage,
	createStreamingAiMessage,
	isUserMessage,
	isAiMessage,
	typeIt,
	fmtPath,
	fmtModels,
	fmtDttm,
	fmtDur,
	fmt2Dec,
	fmtMinSec,
	findFirstTopicWithQuestions,
	findFirstLeafTopic,
	findQuestionInTopic,
	findTopicAndQuestion,
	fmtCal,
	fmtPct,
	shortenString,
	isExpired,
	getExpiresIn,
	fetchTryPackageUrl
}
