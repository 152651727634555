import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Container,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme
}                                                       from "@mui/material"
import {fetchTryPackageUrl, fmt2Dec, fmtDur, fmtMinSec} from "../../util/prepkit-utils"
import Grid                                             from "@mui/material/Unstable_Grid2"
import {useNavigate, useParams}            from "react-router-dom"
import {doGet}                             from "../../util/do-fetch"
import {logger}                            from "../../util/log-utils"
import GradientCard                        from "../GradientCard"
import {ArrowBack, ArrowForward, MoreTime} from "@mui/icons-material"
import {useUserStore}                      from "../../state"
import {trackViewItem}                     from "../../util/analytics"
import Markdown                            from "react-markdown"
import {createCheckoutSession}             from "../../util/mini"
import BirdsyAuth                          from "../auth/BirdsyAuth"
import LoadingDots                         from "../LoadingDots"
import {Helmet}                            from "react-helmet-async"

const log = logger("MiniPackage", 1)

const MiniPackage = ({
	                     // Direct props when used as a child component
	                     categoryToken: propsCategoryToken,
	                     packageToken: propsPackageToken,
	                     prepKit,
                     }) => {

	const params = useParams()
	const theme = useTheme()
	const navigate = useNavigate()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { user } = useUserStore()
	const [selectedPackage, setSelectedPackage] = useState()
	const [trialAvailable, setTrialAvailable] = useState(true)
	const [loading, setLoading] = useState(true)
	const [loadingTrial, setLoadingTrial] = useState(false)
	const [checkExpired, setCheckExpired] = useState(null)

	const isExpired = useMemo(() => {
		return prepKit && new Date(prepKit.expires_at).getTime() <= new Date().getTime()
	}, [prepKit, checkExpired])

	const expiresIn = useMemo(()=>{
		return prepKit && (new Date(prepKit.expires_at).getTime() - (new Date().getTime()))
	}, [prepKit, checkExpired])

	useEffect(() => {
		if(prepKit) {
			const interval = setInterval(() => {
				setCheckExpired(new Date().getTime())
			}, 1000) // check every second

			return () => {
				clearInterval(interval)
			}
		}
	}, [prepKit])


	// Consolidate route and prop values
	const tokens = useMemo(() => {
		return {
			category: propsCategoryToken || params.categoryToken,
			package: propsPackageToken || params.packageToken,
			action: params.action,
			optionId: params.optionId
		};
	}, [propsCategoryToken, propsPackageToken, params]);
	
	const selectedOption = useMemo(()=>{
		return selectedPackage &&
			tokens.optionId &&
			selectedPackage.options.find(o => o.id === tokens.optionId)
	}, [selectedPackage, tokens.optionId])

	useEffect(() => {
		if (selectedPackage) {
			trackViewItem(selectedPackage, selectedOption) // <-- it's okay if selectedOption is null
		}
	}, [selectedPackage, selectedOption])

	useEffect(() => {
		if (!tokens.category || !tokens.package) return

		const controller = new AbortController()

		const fetchData = async () => {
			setLoading(true)
			try {
				const response = await doGet(
					`/mini/shop/${tokens.category}/${tokens.package}`,
					{ signal: controller.signal }
				)
				const data = await response.json()
				setSelectedPackage(data)
			}
			catch (err) {
				if (err.name !== 'AbortError') {
					log.error(err)
				}
			}
			finally {
				setLoading(false)
			}
		}

		fetchData()
		return () => controller.abort()
	}, [tokens.category, tokens.package])

	useEffect(() => {
		if(selectedPackage && user && tokens.action === 'try') {
			handleTryPackage()
		}

		else if(selectedPackage && tokens.optionId && user && tokens.action === 'buy') {
			const gotoCheckout = async () => {
				window.location.href  = await createCheckoutSession(selectedPackage, selectedOption, prepKit && prepKit.id)
			}
			gotoCheckout()
		}

	}, [selectedPackage, selectedOption, user, tokens.optionId, tokens.action])

	const handleSelectOption = async (selectedPkg, selectedOpt) => {
		if (!user) {
			navigate(`buy/${selectedOpt.id}`)
			return
		}

		try {
			setLoading(true)
			const checkoutUrl = await createCheckoutSession(selectedPkg, selectedOpt, prepKit && prepKit.id)
			// Use window.location only for external URLs
			if (checkoutUrl.startsWith('http')) {
				window.location.href = checkoutUrl
			} else {
				navigate(checkoutUrl)
			}
		}
		catch (err) {
			log.error(err)
		}
		finally {
			setLoading(false)
		}
	}

	const handleTryPackage = async () => {
		if(!user) {
			navigate('try')
		}
		else {
			setLoadingTrial(true)
			try {
				const url = await fetchTryPackageUrl(selectedPackage)
				navigate(url)
			}
			catch (err) {
				setTrialAvailable(false)
			}
			finally {
				setLoadingTrial(false)
			}
		}
	}

	if(!selectedPackage) {
		return <Box p={2} sx={{textAlign: 'center'}}>
			<LoadingDots/>
		</Box>
	}

	let trialComp

	if(prepKit) {
		trialComp = null
	}
	else if(loadingTrial){
		trialComp = <CircularProgress size={18} color="secondary"/>
	}
	else if(trialAvailable) {
		trialComp = <Button onClick={handleTryPackage}
		                    size={"large"}
		                    sx={{
								color: selectedPackage.category.color,
			                    borderColor: selectedPackage.category.color
							}}
		                    variant={"outlined"}
		>
			Try a complimentary study session
			<ArrowForward/>
		</Button>
	}
	else {
		trialComp = <Box display="inline-block" textAlign={"left"}>
			<Alert color={"secondary"}>
				<AlertTitle>Trial Expired</AlertTitle>
				You've already used your Complimentary Study Session. Select an option above
				to continue.
			</Alert>
		</Box>
	}

	if(loading) {
		return <Box p={2} sx={{textAlign: 'center'}}>
			<LoadingDots/>
		</Box>
	}

	return (
		selectedPackage &&
		<Container style={{maxWidth: '1200px'}}>
			<Helmet>
				<title>{`Birdsy - ${selectedPackage.name} - ${selectedPackage.category?.name}`}</title>
			</Helmet>
			<Box p={2} sx={{textAlign: 'center'}}>
				<Box display={"flex"}
				     justifyContent={"center"}
				     alignItems={"center"}
				     ml={-7}
				>
					<IconButton onClick={() => {
						prepKit
							? navigate(`/prepkit`)
							: navigate(`/prep/${tokens.category}`)
					}}
					            sx={{mr: 1}}
					>
						<ArrowBack fontSize={"large"}/>
					</IconButton>
					<Typography variant={isSmallScreen ? "h5" : "h2"} style={{textAlign: 'center'}}>
						{
							prepKit &&
							<Typography variant="h2">
								Extend Your
							</Typography>
						}
						{selectedPackage.name} PrepKit
					</Typography>
				</Box>

				{
					prepKit
						? <Typography variant="h6"
						              sx={{
										  mt: 1,
							              py: 1,
							              px: 6,
							              display: "inline-flex",
							              alignItems: "center",
							              gap: 1,
							              color: "red",
						              }}
						>
							<MoreTime/>
							Your PrepKit
							{
								isExpired
									? ` has expired. `
									: <span>expires in <span style={{ fontFamily: 'Courier', fontWeight: 'normal'}}>{fmtMinSec(expiresIn)}.</span></span>
							}
							Select an option below to extend access.
						</Typography>
						: <Typography variant="h6"
						              sx={{
							              py: 2,
							              px: 8,
							              color: selectedPackage.category.color
						              }}
						>
							{selectedPackage.tagline}
						</Typography>
				}
				<Grid container>
					{
						selectedPackage &&
						selectedPackage.options.map(opt => (
								<Grid xs={12} sm={4}
								      key={`option-${opt.id}`}
								      sx={{p: 1, mt: 2}}
								>

									<GradientCard
										dense={true}
										square={false}
										title={null}
										caption={null}
										color={"white"}
										backgroundColor1={selectedPackage.category.color}
										backgroundColor2={selectedPackage.category.color2}
										onClick={() => handleSelectOption(selectedPackage, opt)}
									>
										<CardContent sx={{p: 0}}>
											<Typography variant="h5" sx={{mt: -1}}>
												<b>{opt.name}</b>
											</Typography>
											<Typography variant="h6">
												${fmt2Dec(opt.price_in_cents / 100)}
											</Typography>
										</CardContent>
										<Box justifyContent="center" alignItems="center">
											<Box
												sx={{
													display: 'inline-block',
													padding: '4px 12px',
													minWidth: '80px',
													border: "0px solid magenta",
													backgroundColor: 'rgba(0, 0, 0, 0.2)',
													borderRadius: 1,
													mt: 2
												}}
											>
												{
													tokens.optionId
														? <CircularProgress size={18} sx={{mt: 1, color: "white"}}/>
														: <Typography variant="body2" color="inherit">
															{
																prepKit
																	? 'Extend PrepKit'
																	: 'Choose Plan'
															}
														</Typography>
												}
											</Box>
										</Box>
									</GradientCard>
								</Grid>
							)
						)
					}

					{
						trialComp &&
						<Grid xs={12}
						      key={`option-trial`}
						      sx={{p: 1, mt: 3}}
						>
							{trialComp}
						</Grid>
					}
				</Grid>

				<Card
					elevation={0}
					sx={{
						width: '100%',
						cursor: 'pointer',
						transition: 'backgroundColor 500ms',
						backgroundColor: theme.palette.background.default,
						borderRadius: 5,
						p: 3,
						mt: 5
					}}
				>
					<CardContent sx={{textAlign: "left"}}>
						<Grid container spacing={8}>
							<Grid xs={12} sm={8}>
								<Typography variant={"h5"}>
									About this PrepKit
								</Typography>
								<Box sx={{mt: 1, fontSize: 18}}>
									<Markdown>
										{selectedPackage.description}
									</Markdown>
								</Box>
							</Grid>
							<Grid xs={12} sm={4} sx={{borderLeft: `1px solid ${theme.palette.divider}`}}>
								<Typography variant={"h5"}>
									PrepKit Includes
								</Typography>
								{
									selectedPackage.products.map(p => (
										<Box key={`product-${p.id}`}
										     display={"flex"}
										     flexDirection={"row"}
										     justifyContent={"flex-start"}
										     alignItems={"flex-start"}
										     mb={1.5}
										>
											<Typography sx={{fontSize: 24, mr: 1}}>🎯</Typography>
											<Typography sx={{mt: 1, fontSize: 18}}>
												{p.name}
											</Typography>
										</Box>
									))
								}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Box>
		</Container>
	)
}

export default MiniPackage
