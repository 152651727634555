import { Card, CardContent, Typography, Box } from '@mui/material'
import React, {useMemo}                       from 'react'
import {convertToRgba}                        from "../util/utils"
import useMediaQuery                          from "@mui/material/useMediaQuery"


const GradientCard = ({title, subtitle, caption, color, dense=false, square=true, glow=true, backgroundColor1, backgroundColor2, onClick, children}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))

	const shadowColor = useMemo(()=>{
		return convertToRgba(backgroundColor2, 0.8)
	}, [backgroundColor2])

	const hoverShadowColor = useMemo(()=>{
		return convertToRgba(backgroundColor2, 1)
	}, [backgroundColor2])


	return (
		<Card
			sx={{
				padding: dense ? 0 : 2,
				display: "flex",
				width: '100%',
				borderRadius: 3,
				borderBottom: {
					xs: 'none',
					sm: 'none',
					md: glow ? `1px solid ${backgroundColor1}` : 'none'
				},
				boxShadow: {
					xs: 'none',
					sm: 'none',
					md: glow ? `0px 24px 40px -14px ${shadowColor}` : 'none'
				},
				'&:hover': {
					boxShadow: {
						xs: 'none',
						sm: 'none',
						md: glow ? `0px 24px 40px -14px ${hoverShadowColor}` : 'none'
					}
				},
				transition: `box-shadow 500ms`,
				background: `linear-gradient(0deg, ${backgroundColor2}, ${backgroundColor1})`,
				color: color,
				overflow: 'hidden',
				cursor: onClick ? 'pointer' : 'default',
				aspectRatio: { xs: 'auto', sm: 'auto', md: square ? '1 / 1' : 'auto' }, // Aspect ratio only applies
				// on md
				// and up
				minWidth: 0, // Ensures it doesn’t exceed container constraints
			}}
			onClick={onClick}
		>
			<CardContent
				sx={{
					width: "100%",
					gap: { xs: 2, sm: 2, md: 0 }, // Spacing between items on smaller screens
				}}
			>
				<Box
					sx={{
						textAlign: "left",
						display: 'flex',
						flexDirection: { xs: 'row', sm: 'row', md: 'column' }, // Row on small screens, column on medium and up
						justifyContent: { xs: 'space-between', sm: 'space-between', md: 'space-between' },
						alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'flex-start' },
						width: "100%",
						border: "0px solid orange",
						gap: { xs: 2, sm: 2, md: 0 }, // Spacing between items on smaller screens
					}}
				>
					<Box display={"flex" }
					     flexDirection={"column"}
					     border={"0px solid red"}
					>
						<Typography variant={"h5"} sx={{ fontWeight: 700 }}>
							{title}
						</Typography>
						<Typography variant={"h5"} sx={{ fontWeight: 700, mb: { xs: 0, md: 2 } }}>
							{subtitle}
						</Typography>
					</Box>

					{caption && (
						<Box
							sx={{
								display: 'inline-block',
								padding: '4px 12px',
								border: "0px solid magenta",
								backgroundColor: 'rgba(0, 0, 0, 0.2)',
								borderRadius: 1,
								mt: { xs: 0, sm: 0, md: 0 }, // Top margin on medium and up screens
							}}
						>
							<Typography variant="body2" color="inherit">
								{caption}
							</Typography>
						</Box>
					)}
				</Box>
				{children}
			</CardContent>

		</Card>
	)
}

export default GradientCard
