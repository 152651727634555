import React, {useEffect}                                                    from 'react'
import {Box, Button, Container, Dialog, DialogContent, Typography, useTheme} from "@mui/material"
import BirdsyMiniLogo                                                        from "../BirdsyMiniLogo"
import {Close}                                                               from "@mui/icons-material"
import LeftRightCenter                                                       from "../LeftRightCenter"
import MiniSkeleton                                                          from "./MiniSkeleton"
import SelectPackageList                                                     from "./SelectPackageList"

const TryPackage = ({packages, onTryPackage, onCancel}) => {

	const theme = useTheme()

	return (
		<Container style={{maxWidth: '800px', textAlign: 'center'}}>
			<MiniSkeleton />
			<Dialog
				open={true}
			>
				<Box pt={4}>
					<LeftRightCenter
						left={
							<Box px={2}>
								<Close sx={{color: "transparent"}}/>
							</Box>
						}
						center={
							<Box display="flex" justifyContent="center" pb={2}>
								<BirdsyMiniLogo width={125}/>
							</Box>
						}
						right={
							<Box pb={4}>
								<Button size={"small"} onClick={onCancel}>
									<Close color={"error"}/>
								</Button>
							</Box>
						}
					/>
				</Box>
				<DialogContent sx={{width: 500, px: 5, pb: 4}}>
					<Box display={"flex"}
					     justifyContent={"center"}
					     flexDirection={"column"}
					     pb={2}
					>
						<Typography sx={{fontWeight: 500}}>
							Choose a PrepKit to start your
							<span style={{color: theme.palette.secondary.main}}> complimentary </span>
							study session.
						</Typography>
					</Box>
					<SelectPackageList relatedPackages={packages}
					                   onSelectPackage={onTryPackage}
					/>
				</DialogContent>
			</Dialog>

		</Container>
	)
}

export default TryPackage
