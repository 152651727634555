import {rndTo3Dec}          from "./utils"
import getSupabase          from "./supabase-utils"
import {doPost}             from "./do-fetch"
import {trackBeginCheckout} from "./analytics"

const createCheckoutSession = async (selectedPackage, selectedOption, prepKitId) => {
	if (selectedPackage && selectedOption) {
		const order = {
			packageId: selectedPackage.id,
			optionId: selectedOption.id
		}
		if(prepKitId) {
			order.prepKitId = prepKitId
		}
		const body = JSON.stringify(order)
		const options = { body }
		const res = await doPost(`/stripe/create-checkout-session`, options)
		const session = await res.json()

		trackBeginCheckout(session.id, selectedPackage, selectedOption)

		return session.url
	}
	else {
		throw "PackageId and OptionId are required."
	}
}

const fetchUserProfile = async (userId) => {
	console.log(`fetchUserProfile(${userId})`)
	try {
		const {data, error} = await getSupabase()
			.from('user_profile')
			.select('*')
			.eq('user_id', userId)

		console.log(`done querying user_profile (${userId})`)

		if (error) {
			console.error("Error fetching user profile:", error)
			return null
		}

		return data && data.length > 0 && data[0]
	}
	catch (err) {
		console.error("Error loading userProfile", err)
	}
}

const findQuestionInTopics = (topics, codeOrId) => {
	let foundTopic = null
	let foundQuestion = null
	let foundTopicIndex = -1
	let foundQuestionIndex = -1

	if(topics && topics.length) {
		for (let t = 0; t < topics.length; t++) {
			const top = topics[t]
			if (top.questions) {
				foundQuestionIndex = top.questions.findIndex((ques) => ques.code === codeOrId || ques.id === codeOrId)
				if (foundQuestionIndex !== -1) {
					foundQuestion = top.questions[foundQuestionIndex]
					foundTopic = top
					foundTopicIndex = t
					break
				}
			}
			else if (top.topics) {
				const result = findQuestionInTopics(top.topics, codeOrId)
				if (result.question) {
					foundTopic = result.topic
					foundQuestion = result.question
					foundTopicIndex = result.topicIndex
					foundQuestionIndex = result.questionIndex
					break
				}
			}
		}
	}

	return {
		topic: foundTopic,
		question: foundQuestion,
		topicIndex: foundTopicIndex,
		questionIndex: foundQuestionIndex
	}
}

// helper function to search the entire prepKit
const findQuestionInPrepKit = (prepKit, codeOrId) => {

	let foundProduct = null
	let foundTopic = null
	let foundQuestion = null

	let foundProductIndex = -1
	let foundTopicIndex = -1
	let foundQuestionIndex = -1

	if(prepKit && prepKit.products && codeOrId) {
		for (let i = 0; i < prepKit.products.length; i++) {
			const prod = prepKit.products[i]
			const result = findQuestionInTopics(prod.topics, codeOrId)
			if (result.question) {
				foundProduct = prod
				foundTopic = result.topic
				foundQuestion = result.question
				foundProductIndex = i
				foundTopicIndex = result.topicIndex
				foundQuestionIndex = result.questionIndex
				break
			}
		}
	}
	return {
		product: foundProduct,
		topic: foundTopic,
		question: foundQuestion,
		productIndex: foundProductIndex,
		topicIndex: foundTopicIndex,
		questionIndex: foundQuestionIndex,
	}
}

// recursively search topics and their child topics
const findTopicInTopics = (topics, topicId) => {
	for (const topic of topics) {
		//console.log(`findTopicInTopics topic ===> ${topic.id} ? ${topicId})`)

		if (topic.id == topicId) { // == was on purpose
			return topic
		}
		// If the topic has child topics, recursively search them
		if (topic.topics) {
			const foundTopic = findTopicInTopics(topic.topics, topicId)
			if (foundTopic) {
				return foundTopic
			}
		}
	}
	return null // Topic not found in this branch
}

// Helper function to search the entire prepKit for a topic
const findTopicInPrepKit = (prepKit, topicId) => {
	console.log(`findTopicInPrepKit(${prepKit.id}, ${topicId})`)
	if (prepKit && prepKit.products && topicId) {
		for (const prod of prepKit.products) {
			console.log(`findTopicInPrepKit product ===> ${prod.id})`)
			if (prod.topics) {
				const foundTopic = findTopicInTopics(prod.topics, topicId)
				if (foundTopic) {
					return foundTopic
				}
			}
		}
	}

	// If we made it here, then we did not find the topic
	return null
}

// recursively search topics and their child topics
const findMissedQuestionsAndTopics = (topic) => {
	const arry = []
	// If the topic has child topics, recursively search them
	if (topic.topics) {
		for (const subTopic of topic.topics) {
			const subArry = findMissedQuestionsAndTopics(subTopic)
			arry.push(...subArry)
		}
	}
	else if (topic.questions) {
		const missedQuestions = []
		for (const question of topic.questions) {
			if (question.userAnswer && !question.userAnswer.is_correct) {
				missedQuestions.push(question)
			}
		}
		if (missedQuestions.length > 0) {
			arry.push({
				topic: topic,
				questions: missedQuestions
			})
		}
	}
	return arry
}

const findFirstQuestionInTopic = (topic, match) => {

	match = match || ((q)=>q) // if not match fn provided, use one that returns the first question in the array

	if (topic.questions) {
		const q = topic.questions.find(match)
		if(q) return q
	}

	// Recursive case: If the topic has child topics, search them recursively
	if (topic.topics) {
		for (const childTopic of topic.topics) {
			const firstQuestion = findFirstQuestionInTopic(childTopic, match)
			if (firstQuestion) {
				return firstQuestion // Return as soon as a question is found
			}
		}
	}

	// If no question is found, return null
	return null
}

const findAnswersForQuestion = (answers, question) => {
	return answers.filter(a => a.question_id === question.id)
}

const findMostRecentAnswerForQuestion = (answers, question) => {
	answers = findAnswersForQuestion(answers, question)
	return answers && answers[answers.length-1]
}

const scorePrepKit = (prepKit) => {
	if(!prepKit) return
	console.log('scorePrepKit')

	prepKit.questionCount = 0
	prepKit.answeredCount = 0
	prepKit.correctCount = 0
	prepKit.incorrectCount = 0

	for (let i = 0; i < prepKit.products.length; i++) {
		const product = prepKit.products[i]
		scoreProduct(product)
		prepKit.questionCount += product.questionCount
		prepKit.answeredCount += product.answeredCount
		prepKit.correctCount += product.correctCount
		prepKit.incorrectCount += product.incorrectCount
	}

	// roll up and average scores
	prepKit.percentAnswered = rndTo3Dec(prepKit.answeredCount / prepKit.questionCount)
	prepKit.percentCorrect = rndTo3Dec(prepKit.correctCount / prepKit.questionCount)
}

const scoreProduct = (product) => {
	product.questionCount = 0
	product.answeredCount = 0
	product.correctCount = 0
	product.incorrectCount = 0

	for (let i = 0; i < product.topics.length; i++) {
		const topic = product.topics[i]
		scoreTopic(topic)
		product.questionCount += topic.questionCount
		product.answeredCount += topic.answeredCount
		product.correctCount += topic.correctCount
		product.incorrectCount += topic.incorrectCount
	}

	// roll up and average scores
	product.percentAnswered = rndTo3Dec(product.answeredCount / product.questionCount)
	product.percentCorrect = rndTo3Dec(product.correctCount / product.questionCount)
}

const scoreTopic = (topic) => {
	// assumes question has a userAnswer property that has been set already
	topic.questionCount = 0
	topic.answeredCount = 0
	topic.correctCount = 0
	topic.incorrectCount = 0

	if (topic.topics) {
		for (let i = 0; i < topic.topics.length; i++) {
			const child = topic.topics[i]
			scoreTopic(child)
			topic.questionCount += child.questionCount
			topic.answeredCount += child.answeredCount
			topic.correctCount += child.correctCount
			topic.incorrectCount += child.incorrectCount
		}

		// Calculate average percentAnswered and percentCorrect for parent topics
		topic.percentAnswered = topic.answeredCount / topic.questionCount
		topic.percentCorrect = topic.correctCount / topic.questionCount
	}
	else if (topic.questions) {
		for (let x = 0; x < topic.questions.length; x++) {
			const question = topic.questions[x]
			question.number = x+1

			const answer = question.userAnswer
			if (answer) {
				topic.answeredCount++
				if (answer.is_correct) {
					topic.correctCount++
				}
				else {
					topic.incorrectCount++
				}
			}
		}

		topic.questionCount = topic.questions.length
		topic.percentAnswered = rndTo3Dec(topic.answeredCount / topic.questionCount)
		topic.percentCorrect = rndTo3Dec(topic.correctCount / topic.questionCount)
	}

	//console.log(`${topic.name}: cnt=[${topic.questionCount}] ans=[${topic.answeredCount}]
	// cor=[${topic.correctCount}] answered=[${topic.percentAnswered}%] correct[${topic.percentCorrect}%]`)
}

const INTENDED_URL = 'intendedUrl'
const setIntendedUrl = () => {
	const url = window.location.pathname + window.location.search
	localStorage.setItem(INTENDED_URL, url);
}

const getIntendedUrl = (defaultUrl) => {
	const intendedUrl = localStorage.getItem(INTENDED_URL)
	return intendedUrl || defaultUrl
}

const getIntendedUrlAndRemove = (defaultUrl) => {
	const intendedUrl = localStorage.getItem(INTENDED_URL)
	if(intendedUrl) {
		localStorage.removeItem(INTENDED_URL)
	}
	return intendedUrl || defaultUrl
}

const getRandomElement = (ary) => {
	const rando = Math.floor(Math.random() * ary.length)
	return ary[rando]
}

export {fetchUserProfile, findQuestionInTopics, findMostRecentAnswerForQuestion, findAnswersForQuestion, findQuestionInPrepKit,
	findTopicInPrepKit, findTopicInTopics, findFirstQuestionInTopic, findMissedQuestionsAndTopics,
	getRandomElement, scoreTopic, scorePrepKit, setIntendedUrl, getIntendedUrlAndRemove, getIntendedUrl,
	createCheckoutSession}
